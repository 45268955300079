<template>
  <LayoutWrapper />
</template>

<script>
import LayoutWrapper from './layout/LayoutWrapper';
export default {
  components: {
    LayoutWrapper,
  },
};
</script>

<style></style>

