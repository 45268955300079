<template>
  <section class="layout-wrapper">
    <LayoutTopBar @menubutton-click="onMenuButtonClick" />
    <LayoutSidebar :active="sidebarActive" />
    <div :class="['layout-mask', { 'layout-mask-active': sidebarActive }]" @click="onMaskClick" />
    <section class="layout-content">
      <router-view />
    </section>
  </section>
</template>
<script>
import LayoutSidebar from './LayoutSidebar';
import LayoutTopBar from './LayoutTopBar';
import { ref } from '@vue/reactivity';
export default {
  name: 'LayoutWrapper',
  components: {
    LayoutTopBar,
    LayoutSidebar,
  },
  setup() {
    const sidebarActive = ref(false);

    const onMenuButtonClick = () => {
      if (sidebarActive.value) {
        sidebarActive.value = false;
      } else {
        sidebarActive.value = true;
      }
    };

    const onMaskClick = () => {
      sidebarActive.value = false;
    };

    return { sidebarActive, onMaskClick, onMenuButtonClick };
  },
};
</script>
<style lang="scss">
.layout-wrapper {
  color: var(--text-color);
}

.layout-content {
  margin-left: var(--sidenav-size);
  min-height: calc(100vh - 4rem);
}

@media screen and (max-width: 960px) {
  .layout-content {
    margin-left: 0;
  }

  .layout-mask {
    &.layout-mask-active {
      z-index: var(--z-index-1);
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.2);
      transition: background-color 0.3s;
    }
  }
}
</style>
