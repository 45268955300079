<template>
  <aside :class="['layout-sidebar', { active: active }]">
    <div class="sidebar__title"><h1>¡Hola! 👋🏾</h1></div>
    <div class="sidebar__menu">
      <div class="sidebar__search">
        <AutoComplete
          v-model="selectedRoute"
          :suggestions="filtered"
          @complete="searchRoutes"
          @item-select="selectRoute"
          field="name"
          placeholder="Keyword Search"
          force-selection
        />
      </div>

      <PanelMenu :model="items" />
    </div>
  </aside>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
export default {
  name: 'LayoutSidebar',
  props: {
    active: Boolean,
  },
  setup() {
    const items = ref([
      {
        label: 'Test Dev',
        icon: 'pi pi-fw pi-file',
        items: [
          {
            label: 'Components',
            icon: 'pi pi-fw pi-plus',
            items: [
              {
                label: 'Input List',
                icon: 'pi pi-fw pi-bookmark',
                to: '/input-list',
              },
            ],
          },
        ],
      },
    ]);
    const router = useRouter();
    const selectedRoute = ref();
    const filtered = ref();
    const searchRoutes = (event) => {
      if (!event.query.trim().length) {
        filtered.value = [...router.options?.routes];
      } else {
        filtered.value = router.options?.routes.filter((route) => {
          return route.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    };

    const selectRoute = (e) => {
      router.push(e.value.path);
      selectedRoute.value = '';
    };

    return { items, searchRoutes, selectedRoute, filtered, selectRoute };
  },
};
</script>

<style lang="scss">
.layout-sidebar {
  --easeOutExpo: cubic-bezier(0.16, 1, 0.3, 1);
  --duration: 0.6s;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: transform var(--duration) var(--easeOutExpo);
  user-select: none;
  width: var(--sidenav-size);
  @media (prefers-reduced-motion: reduce) {
    .layout-sidebar {
      --duration: 1ms;
    }
  }
}

.p-panelmenu .p-panelmenu-panel {
  box-shadow: none;
}

.sidebar__title {
  height: 4rem;
  box-shadow: var(--box-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  h1 {
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 0.15rem;
  }
}

.sidebar__search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
}

@media screen and (max-width: 960px) {
  .layout-sidebar {
    transform: translateX(-110vw);
    z-index: var(--z-index-2);

    &.active {
      transform: translateX(0);
    }
  }
}
</style>
