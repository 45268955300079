<template>
  <header class="layout-topbar">
    <Button
      icon="pi pi-bars"
      class="menu-button p-button-link"
      @click="$emit('menubutton-click')"
    />
    <ul class="topbar-menu"></ul>
  </header>
</template>

<script>
export default {
  name: 'LayoutTopBar',
};
</script>

<style lang="scss">
.layout-topbar {
  background: #fff;
  padding: 0;
  height: 4rem;
  box-shadow: var(--box-shadow);
  display: flex;
  align-items: center;
  margin-left: var(--sidenav-size);

  .menu-button {
    display: none;

    &:hover {
      background-color: red;
    }
  }

  .topbar-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 960px) {
  .layout-topbar {
    margin-left: 0;
    padding: 0;

    .menu-button {
      display: block;
    }

    .topbar-menu {
      margin: 0;
    }
  }
}
</style>
